@media (max-width: 991.98px){
    .header-menu-wrapper{
        left:-500px !important
    }
    .header-menu-wrapper.header-menu-wrapper-on{
        left:0px !important
    }
    .subheader-enabled .content {
        min-width: 540px;
    }
    .card-custom.idProofing .max-h-250px {
        max-height: 190px !important;
        margin: 40px auto 40px;
    }
    div#root {
        width: 100%;
        min-width: 520px;
    }
    button.idProofingActionButtons {
        display: block;
        margin: 0 auto;
    }
    .card-custom.idProofing {
        text-align: center;
    }
}
.btn-claim-job-disabled, .btn-claim-job-disabled:hover{
    cursor: default !important;
    background-color: #607D8B !important;
    border-color: #607D8B !important;
    
}
